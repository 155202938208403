<template>
  <div class="allreply">
    <div
      v-for="item in replies"
      :key="item.id"
      class="p10 flex_start bbe9s"
      :style="{ background: item.zt ? '#f5f5f5' : '#fff' }"
    >
      <van-image
        fit="contain"
        :src="item.headerUrl"
        class="mr10"
        width="30"
        height="30"
        round
      ></van-image>
      <div class="flex_column" style="width: 83vw">
        <div class="flex_between flex_items">
          <div class="flex_column">
            <span class="f12 c0 bold">{{ item.nickName }}</span>
            <span class="mt5 f13 c0">{{ item.body }}</span>
          </div>
          <van-image
            fit="contain"
            width="14"
            class="mr10"
            :src="require('@/images/home/xiaoxin1.png')"
          ></van-image>
        </div>
        <div class="flex_between flex_items mt10">
          <div class="flex_start flex_items f12" style="color: #666666">
            <span class="mr10"
              >{{ item.likeNum }}&nbsp;<span v-if="item.likeNum < 2">Like</span
              ><span v-else>Likes</span></span
            >
            <span>Reply</span>
          </div>
          <span class="f12 mr10" style="color: #666666">yesterday 15:27</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      replies: [],
    };
  },
  created() {
    this.replies = JSON.parse(this.$route.params.replies);
    console.log(this.replies)
  },
};
</script>
<style>
body {
  background: #fff;
}
</style>